import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";
import Pyramide from "../images/pyramide.svg";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Alexandra Prenosil"
        description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <Container className="mb-5 mt-3">
        <Row className="justify-content-between align-items-start align-items-lg-center">
          <Col xs={4}>
            <StaticImage
              src="../images/blumentopf.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   110, 133, 150, 201, 276, 376, 451, 526, 220, 266, 300, 402, 552,
              //   752, 902, 1052,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw / 12 * 5 - 24px), (max-width: 767px) 201px, (max-width: 991px) 276px, (max-width: 1199px) 376px, (max-width: 1399px) 451px, 526px"
            />
          </Col>
          {/* <Col xs={12} lg={4}>
          <h1 className="text-center oblique mt-5">
              «Gesundheit ist kein Zustand, sondern ein Prozess»
            </h1>
            </Col> */}
          <Col
            xs={8}
          >
            <Row className="justify-content-start align-items-center">
              <Col xs={{span: 6, offset: 6}} lg={{span: 6, offset: 6}} className="align-self-end">
              <StaticImage
              src="../images/blue-stone-leave-index.png"
              alt=""
              placeholder="tracedSVG"
              // className="d-none d-lg-block"
              // breakpoints={[
              //   110, 133, 150, 201, 276, 376, 451, 526, 220, 266, 300, 402, 552,
              //   752, 902, 1052,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw / 12 * 5 - 24px), (max-width: 767px) 201px, (max-width: 991px) 276px, (max-width: 1199px) 376px, (max-width: 1399px) 451px, 526px"
            />
            </Col>
            <Col xs={12} md={{span: 8, offset: 0}} className="justify-content-start align-items-start">
            <h1 className="text-center oblique mt-4 mt-lg-5">
              «Gesundheit ist kein Zustand, sondern ein Prozess»
            </h1>
              </Col>
              </Row>
          </Col>
          {/* <Col xs={6}>
            <StaticImage
              src="../images/blue-small-stone-leave-index.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   110, 133, 150, 201, 276, 376, 451, 526, 220, 266, 300, 402, 552,
              //   752, 902, 1052,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw / 12 * 5 - 24px), (max-width: 767px) 201px, (max-width: 991px) 276px, (max-width: 1199px) 376px, (max-width: 1399px) 451px, 526px"
            />
          </Col> */}
        </Row>
      </Container>

      {/* <div className="hero mt-md-5">
        <StaticImage
          src="../images/shutterstock_1818780554.jpg"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
        />
      </div> */}

      {/* <Container className="mt-md-5">
        <Row className="justify-content-end">
          <Col
            xs={12}
            sm={10}
            md={9}
            lg={8}
            xl={7}
            xxl={6}
          >
            <StaticImage
              src="../images/home-quote-stone-b.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   110, 133, 150, 201, 276, 376, 451, 526, 220, 266, 300, 402, 552,
              //   752, 902, 1052,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw / 12 * 5 - 24px), (max-width: 767px) 201px, (max-width: 991px) 276px, (max-width: 1199px) 376px, (max-width: 1399px) 451px, 526px"
            />
            <h1 className="text-center oblique">
              «Gesundheit ist kein Zustand, sondern ein Prozess»
            </h1>
          </Col>
        </Row>
      </Container> */}

      <Container className="py-5">
        <Row className="justify-content-center">
          <Col>
            <h2 className="section-title">
              Herzlich willkommen in meiner Praxis
            </h2>
            <p className="lead">
              Als Naturheilpraktikerin nehme ich Sie ganzheitlich wahr und lege
              den Fokus auf die Ursachen Ihrer Beschwerden, statt mich nur an
              einzelnen Symptomen zu orientieren. Ich arbeite mit mehreren
              Therapiemethoden, die sich optimal ergänzen, um ihre
              Selbstheilungskräfte anzuregen, Ihr Immunsystem zu stärken und sowohl Körper, als auch Geist
              und Seele ansprechen. Ich freue mich, Sie auf Ihrem Weg zu mehr
              Gesundheit, Wohlbefinden, Energie und Lebensfreude zu begleiten.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <Row>
          <Col>
            <h2 className="section-title">Therapiemethoden</h2>
          </Col>
        </Row>
        <Row className="d-flex align-items-stretch g-3 g-lg-4 my-3">
          <Col xl={6} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-quinary">
              <Row className="g-0 w-100">
                <Col sm={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/shutterstock_1560390422.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col sm={6}>
                  <div className="p-3 p-lg-4 d-flex flex-column justify-content-between h-100">
                    <h3 className="h4">
                      Neurobiologie nach Dr. med. Klinghardt
                    </h3>
                    <Link
                      className="mehr-info align-self-end"
                      activeClassName="active"
                      to="/therapiemethoden#klinghardt"
                    >
                      mehr Info &gt;
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col xl={6} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-tertiary">
              <Row className="g-0 w-100">
                <Col sm={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/shutterstock_270038441.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col sm={6}>
                  <div className="p-3 p-lg-4 d-flex flex-column justify-content-between h-100">
                    <h3 className="h4">
                      Biophotonen&shy;therapie mit dem CHIREN
                    </h3>
                    <Link
                      className="mehr-info align-self-end"
                      activeClassName="active"
                      to="/therapiemethoden#energiemedizin"
                    >
                      mehr Info &gt;
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col xl={6} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-secondary">
              <Row className="g-0 w-100">
                <Col sm={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/shutterstock_171481475.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col sm={6}>
                  <div className="p-3 p-lg-4 d-flex flex-column h-100">
                    <h3 className="h4 text-background">
                    Ernährungs&shy;beratung in der Praxis oder online
                    </h3>
                    <Link
                      className="mehr-info align-self-end text-background mt-auto"
                      activeClassName="active"
                      to="/therapiemethoden#darmgesundheit"
                    >
                      mehr Info &gt;
                    </Link>
                    <h3 className="h4 text-background mt-3">
                    Spezial-Pakete für Detox, Leberkur und Darmaufbau
                    </h3>
                    <Link
                      className="mehr-info align-self-end text-background mt-auto"
                      activeClassName="active"
                      to="/therapiemethoden#spezialangebote"
                    >
                      mehr Info &gt;
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col xl={6} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-quaternary">
              <Row className="g-0 w-100">
                <Col sm={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/shutterstock_1143520484.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col sm={6}>
                  <div className="p-3 p-lg-4 d-flex flex-column justify-content-between h-100">
                    <h3 className="h4">
                      Farblicht&shy;therapie mit dem PHOTONWAVE
                    </h3>
                    <Link
                      className="mehr-info align-self-end"
                      activeClassName="active"
                      to="/therapiemethoden#photonwave"
                    >
                      mehr Info &gt;
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      {/* <Container className="py-5">
        <Row>
          <Col>
            <h2 className="section-title">PAKET-Spezialangebote Ernährungsberatung</h2>
            <h4 className="section-title mb-4">
              Mit Detox-Programm und Power-Food die Zellalterung verlangsamen
              und die Zellregeneration anregen
            </h4>
            <p className="lead">
              Hand aufs Herz: wie viele Diäten haben Sie schon ausprobiert? Wie
              oft haben Sie den guten Vorsatz ausgesprochen, «gesünder» zu
              essen? Wie viel Geld haben Sie bereits für vielversprechende
              Pillen und Pülverchen ausgegeben, die Ihnen schnelles Abnehmen,
              jüngeres Aussehen, mehr Energie und Konzentrationsvermögen
              versprochen haben? Hat es den gewünschten Effekt gebracht? Nein?
              Reden Sie sich bitte nicht ein, Sie schaffen das nicht!
            </p>
          </Col>
        </Row>

        <Row className="g-3 g-lg-4 my-3">
          <Col lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column justify-content-between h-100 bg-tertiary spezial-pakete">
              <h3 className="h4">Ernährungs&shy;beratung light</h3>
              <Link
                className="mehr-info align-self-end"
                activeClassName="active"
                to="/therapiemethoden#spezialangebote"
              >
                mehr Info &gt;
              </Link>
            </div>
          </Col>

          <Col lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column justify-content-between h-100 bg-quinary spezial-pakete">
              <h3 className="h4">
                Ernährungs&shy;beratung mit Organ-Check und Ausleitung von
                Giftstoffen
              </h3>
              <Link
                className="mehr-info align-self-end"
                activeClassName="active"
                to="/therapiemethoden#spezialangebote"
              >
                mehr Info &gt;
              </Link>
            </div>
          </Col>

          <Col lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column justify-content-between h-100 bg-quaternary spezial-pakete">
              <h3 className="h4">
                Detox-Programm mit Leberreinigung, Darmaufbau und
                Ernährungs&shy;beratung
              </h3>
              <Link
                className="mehr-info align-self-end"
                activeClassName="active"
                to="/therapiemethoden#spezialangebote"
              >
                mehr Info &gt;
              </Link>
            </div>
          </Col>
        </Row>
      </Container> */}

      {/* <Container>
        <Row>
          <Col>
          <div className="p-5 bg-secondary text-background">
          <h3 className="h4 text-background">
              PAKET-Spezialangebote Ernährungsberatung
            </h3>
            <p className="lead mb-0">
              Mit Detox-Programm und Power-Food Abnehmen, das Wohlbefinden
              steigern, Entgiften, Entschlacken, die Zellalterung verlangsamen
              und die Zellregeneration anregen.
            </p>
            </div>
          </Col>
        </Row>
      </Container> */}

      <Container className="py-5">
        <h2 className="section-title">
          5-Ebenen-Heilmodell von Dr. med. Klinghardt
        </h2>
        <h4 className="section-title mb-4">Körper, Geist und Seele</h4>
        <Row>
          <Col lg={6} xxl={6}>
            <p className="lead">
              Ich arbeite grundsätzlich nach dem 5-Ebenen-Heilmodell von Dr.
              Klinghardt. Danach hat der Mensch fünf «Körper» oder Ebenen, die
              Sie in der Abbildung als Pyramide dargestellt sehen.{" "}
              {/* <a
                href="https://www.ink.ag/dr.-klinghardt/vita/"
                target="_blank"
                rel="noreferrer"
              >
                Mehr zu Dr. Klinghardt
              </a> */}
            </p>
            <p className="d-flex"><a
                      className="mehr-info align-self-end ms-auto"
                      href="https://www.ink.ag/dr.-klinghardt/vita/"
                target="_blank"
                rel="noreferrer"
                    >
                      Mehr zu Dr. Klinghardt &gt;
                    </a></p>
            <p>
              Die Ursache einer körperlichen oder seelischen Erkrankung oder
              eines Ungleichgewichts kann auf jeder dieser fünf Ebenen liegen.
              Grundsätzlich gilt: Krank machende Einflüsse auf den höheren
              Ebenen dringen nach «unten» durch und werden dann erst als
              Erkrankung sichtbar und erfahrbar. Nachhaltige Verbesserung der
              Gesundheit wird also erreicht, wenn auf der Ebene behandelt wird,
              auf der die wirkliche Krankheitsursache liegt. Eine Liste von
              Krankheiten oder deren Symptome aufzuzählen, die ich in meiner
              Praxis behandeln kann, erscheint mir deshalb der falsche Ansatz.
              Das bespreche ich lieber persönlich mit Ihnen.
            </p>
          </Col>
          <Col
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 0 }}
            xxl={{ span: 6, offset: 0 }}
            className="p-3"
          >
            <Pyramide className="pyramide" alt="pyramide-5-ebene-Heolmodell " />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col xs={10} md={8} lg={7} xl={6}>
            <StaticImage
              src="../images/home-sun-line-plant.png"
              alt=""
              placeholder="tracedSVG"
              // breakpoints={[
              //   243, 289, 321, 426, 456, 486, 536, 546, 578, 636, 642, 852, 912,
              //   1072, 1092, 1272,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw /12 * 10 - 24px), (max-width: 767px) 426px, (max-width: 991px) 456px, (max-width: 1199px) 536px, (max-width: 1399px) 546px, 636px"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;
